import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "client" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_table = _resolveComponent("common-table")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_common_table, { options: _ctx.options }, null, 8, ["options"])
  ]))
}