
import { defineComponent } from "vue-demi";
import ClientMain from "@/components/admin/client/index.vue";

export default defineComponent({
  name: "ClientView",
  components: {
    ClientMain,
  },
});
